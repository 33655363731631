// src/services/translationService.js

import axiosInstance from '../setup/axiosInstance';
import proxy from '../setup/proxy';

export const fetchTranslations = async ({ queryKey }) => {
  let [_, lng, ns] = queryKey; // lng: kod języka, ns: przestrzeń nazw (np. common, landing_page)
  lng = lng || 'en';
  const { data } = await axiosInstance.get(`${proxy}/locales/${lng}/${ns}.json`);
  return data;
};
