import React, { useState, useEffect, useCallback, useRef } from "react"; 
import { useNavigate } from "react-router-dom";
import axiosInstance from "../setup/axiosInstance.js";
import proxy from '../setup/proxy';

// translations
import { useTranslation } from 'react-i18next';
import  useTranslationStore from '../stores/TranslationStore.js';

const SKUlist = ({
    onSKUSelect, 
    showFilterManualOrder = false,
    showFilterPhotosAvailable = false,  
    showFilterVideosAvailable = false,
    showFilterIntragroupProducts = false,
    showVisualsQuantity = false,
    isProductPage = false,
    pageKey = null,
    selectedSku = null,
    skuList
}) => {
  const [skus, setSkus] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterManualOrder, setFilterManualOrder] = useState(false);
  const [filterPhotosAvailable, setFilterPhotosAvailable] = useState(false);
  const [filterVideosAvailable, setFilterVideosAvailable] = useState(false);
  const [filterIntragroupProducts, setFilterIntragroupProducts] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState(selectedSku);
  const [isLoading, setIsLoading] = useState(false); // Nowy stan do śledzenia ładowania danych
  const isInitialMount = useRef(true);

  const [isOpen, setIsOpen] = useState(false);

    //translations setup
    const {t} = useTranslation('common');
    const isReady = useTranslationStore((state) => state.isReady);
  

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setSelectedSKU(selectedSku);
  }, [selectedSku]);

  const fetchSkus = useCallback(async () => {
    setIsLoading(true); // Ustawiamy isLoading na true przed rozpoczęciem ładowania danych

    if (filterIntragroupProducts) {
      try {
        const response = await axiosInstance.get(`${proxy}/api/products/get-sku-list-intra`);
        setSkus(response.data || []);
        console.log("Fetched SKUs from API", response.data);
      } catch (error) {
        console.log("Error fetching SKU list", error);
      }
    } else {
      setSkus(skuList);

    }

    setIsLoading(false); // Ustawiamy isLoading na false po zakończeniu ładowania danych
  }, [filterIntragroupProducts, skuList]);

  useEffect(() => {
    fetchSkus();
  }, [fetchSkus]);

  useEffect(() => {
    if (isInitialMount.current) {
      if (isProductPage) {
        // GetLandingPageSkus();
      } else {
        // GetAllSkus();
      }
      isInitialMount.current = false;
    }
  }, [isProductPage]);

  const handleManualOrderChange = (e) => {
    setFilterManualOrder(e.target.checked);
  };

  const handleIntragroupProductsChange = (e) => {
    setFilterIntragroupProducts(e.target.checked)
  }

  const handlePhotosAvailableChange = (e) => {
    setFilterPhotosAvailable(e.target.checked);
  };

  const handleVideosAvailableChange = (e) => {
    setFilterVideosAvailable(e.target.checked);
  };

  const navigate = useNavigate();
  const goTo = useCallback((sku) => {
    console.log("Navigating to", `/offer/${pageKey}/${sku}`);
    navigate(`/offer/${pageKey}/${sku}`, { replace: true });
  }, [navigate, pageKey]);

  const handleSKUClick = (sku) => {
    setSelectedSKU(sku);
    if (isProductPage) goTo(sku);
    else onSKUSelect(sku);
  };

  const filteredSkus = skus.filter(sku => {
    const matchesSearch = sku.sku.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesManualOrder = filterManualOrder ? sku.manual_order === 0 : true;
    const matchesPhotosAvailable = filterPhotosAvailable ? sku.photo_count > 0 : true;
    const matchesVideosAvailable = filterVideosAvailable ? sku.video_count > 0 : true;
    return matchesSearch && matchesManualOrder && matchesPhotosAvailable && matchesVideosAvailable;
  });

  return (
    <div className="container my-3 sku-list-base">
      <div className="text-center">
        <p className="h4 pt-2">{t('sku_list')}</p>
      </div>

      <div className="text-left mb-3">
        <button className="btn btn-primary mb-2 d-lg-none" type="button" onClick={toggle}>
          {t('toggle_sku_list')}
        </button>
        <div className={`collapse ${isOpen ? 'show' : ''} d-lg-block`}>
          {showFilterIntragroupProducts && (
            <div className="form-check">
              <input
                type="checkbox"
                onChange={handleIntragroupProductsChange}
                className="form-check-input"
                id="manualOrder"
              />
              <label className="form-check-label" htmlFor="manualOrder">
                {t('only_from_intragroup')}
              </label>
            </div>
          )}
          {showFilterManualOrder && (
            <div className="form-check">
              <input
                type="checkbox"
                checked={filterManualOrder}
                onChange={handleManualOrderChange}
                className="form-check-input"
                id="manualOrder"
              />
              <label className="form-check-label" htmlFor="manualOrder">
                {t('with_manual_order')}
              </label>
            </div>
          )}
          {showFilterPhotosAvailable && (
            <div className="form-check">
              <input
                type="checkbox"
                checked={filterPhotosAvailable}
                onChange={handlePhotosAvailableChange}
                className="form-check-input"
                id="photosAvailable"
              />
              <label className="form-check-label" htmlFor="photosAvailable">
                {t('with_photos')}
              </label>
            </div>
          )}
          {showFilterVideosAvailable && (
            <div className="form-check">
              <input
                type="checkbox"
                checked={filterVideosAvailable}
                onChange={handleVideosAvailableChange}
                className="form-check-input"
                id="videosAvailable"
              />
              <label className="form-check-label" htmlFor="videosAvailable">
                {t('with_videos')}
              </label>
            </div>
          )}
          <div className="mb-2">
            <input
              type="text"
              placeholder={t('search_sku')}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
      </div>

      <div className={`collapse ${isOpen ? 'show' : ''} d-lg-block`}>
        {isLoading ? (
          <div className="text-center">Loading...</div> // Wyświetl komunikat "Loading..." podczas ładowania danych
        ) : (
          <ul className="list-group sku-list overflow-auto">
            {filteredSkus.map(sku => (
              <li
                className={`list-group-item ${sku.sku === selectedSKU ? 'active' : ''}`}
                key={sku.sku}
                onClick={() => handleSKUClick(sku.sku)}
                id={sku.sku}
              >
                {sku.sku} {showVisualsQuantity && (
                  filterPhotosAvailable && filterVideosAvailable ? `(${sku.visuals_quantity})` :
                  filterPhotosAvailable ? `(${sku.photo_count})` :
                  filterVideosAvailable ? `(${sku.video_count})` : `(${sku.visuals_quantity})`
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SKUlist;
