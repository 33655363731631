// components/descriptions/ProductDataSection.js

import Field from "./FormField";
import { CategoryDivider } from "../visual/headers.js";
import proxy from "../../setup/proxy";

const ProductDataSection = ({ sku, globalData, handleGlobalData }) => (
    <div className="container px-5">
      <div className="row">
        <div className="col-12 col-md-2">
          <img
            src={`${proxy}/img/main/${sku}`}
            alt="Product"
            className="img-fluid"
          />
        </div>
        <div className="col-12 col-md-10">
        <CategoryDivider category="General product data" />
          <Field
            label="SKU"
            name="sku"
            value={sku} 
            onChange={() => {}}
            type="text"
          />
          <Field
            label="Business Description"
            name="business_description"
            value={globalData.productData.business_description}
            onChange={handleGlobalData("productData")}
            textarea
          />
          <Field
            label="Commercial Category"
            name="commercial_category"
            value={globalData.productData.commercial_category}
            onChange={handleGlobalData("productData")}
          />
          <Field
            label="Name"
            name="name"
            value={globalData.productData.name}
            onChange={handleGlobalData("productData")}
          />

            <CategoryDivider category="Query data" />

          <Field
            label="Product Type"
            name="product_type"
            value={globalData.commercialCategory.product_type}
            onChange={handleGlobalData("commercialCategory")}
          />
          <Field
            label="Highlights"
            name="highlights"
            value={globalData.commercialCategory.highlights}
            onChange={handleGlobalData("commercialCategory")}
            textarea
          />
          <Field
            label="Key Feature (Single)"
            name="key_feature"
            value={globalData.commercialCategory.key_feature}
            onChange={handleGlobalData("commercialCategory")}
            textarea
          />
          <Field
            label="Additional Info (Category Based)"
            name="commercial_category_additional_info"
            value={globalData.commercialCategory.commercial_category_additional_info}
            onChange={handleGlobalData("commercialCategory")}
            textarea
          />
          <Field
            label="Additional Info (Name Based)"
            name="product_name_additional_info"
            value={globalData.additionalInfo.product_name_additional_info}
            onChange={handleGlobalData("additionalInfo")}
            textarea
          />
        </div>
      </div>
    </div>
  );
  
export default ProductDataSection;