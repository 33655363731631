import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosInstance from "../../setup/axiosInstance";
import proxy from "../../setup/proxy";
import { Card, Button, Form } from "react-bootstrap";
import GptBatchMonitor from "../../components/GptBatchMonitor";
import { set } from "react-hook-form";

function AdminControl() {
  const [loading, setLoading] = useState(false);
  const [dbTables, setDbTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [order, setOrder] = useState("int-ext");
  const [syncStatuses, setSyncStatuses] = useState([]);
  const [visSyncStatuses, setVisSyncStatuses] = useState([]);
  const [rcloneSyncStatus, setRcloneSyncStatus] = useState([]);
  const [translateBusinessDesc, setTranslateBusinessDesc] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  // maintenence handeling
  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await axiosInstance.get(`${proxy}/api/app-state`);
        setIsUnderMaintenance(response.data.isUnderMaintenance || false); // Zabezpieczenie przed undefined
      } catch (error) {
        console.error(error);
      }
    };
    fetchState();
  }, []);

  const handleToggleBusinessDescTrans = () => {
    const newState = !translateBusinessDesc; // Użyjemy nowego stanu bezpośrednio
    setTranslateBusinessDesc(newState);
  };



  const handleToggle = async () => {
    const newState = !isUnderMaintenance; // Użyjemy nowego stanu bezpośrednio

    try {
      const response = await axiosInstance.post(`${proxy}/api/app-state`, {
        isUnderMaintenance: newState,
      });
      setIsUnderMaintenance(response.data.isUnderMaintenance); // Zaktualizuj stan na podstawie odpowiedzi z backendu
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleOrder = () => {
    setOrder(order === "int-ext" ? "ext-int" : "int-ext");
  };

  // pobieranie listy tablic z bazy danych
  useEffect(() => {
    const fetchDbTables = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`${proxy}/api/db/tables`);

        // Sprawdzenie, czy dane są już tablicą; jeśli nie, przekształcenie na tablicę
        const tables = Array.isArray(response.data.tables)
          ? response.data.tables
          : [response.data.tables];

        setDbTables(tables);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDbTables();
  }, []);

  // Web Socket

  useEffect(() => {
    const ws = new WebSocket("wss://compactorcloud.com/DB");

    ws.onmessage = (event) => {
      console.log("WebSocket message received:", event.data);
      const message = JSON.parse(event.data);

      // Sprawdź, czy wiadomość zawiera klucz "step"
      if (message.step) {
        // Aktualizacja stanu synchronizacji tylko, gdy "step" istnieje
        setSyncStatuses((prevStatuses) => [
          ...prevStatuses,
          {
            step: message.step,
            status: message.status,
            message: message.message,
          },
        ]);
        if (message.step && message.status === "completed") {
          console.log("Completed");
          refreshBatchMonitor();
        }
      }

      // wiadomości z websocketa dla Visual sync
      if (message.type === "visualSync") {
        setVisSyncStatuses((prevStatuses) => [
          ...prevStatuses,
          {
            step: message.step,
            status: message.status,
            message: message.message,
          },
        ]);
        if (message.step && message.status === "completed") {
          console.log("Completed");
          refreshBatchMonitor();
        }
      }

      if (message.type === "rclone" && message.status === "sync") {
        setRcloneSyncStatus({
          transferred: message.data.transferred,
          checks: message.data.checks,
          elapsedTime: message.data.elapsedTime,
          transferring: message.data.transferring,
        });
      }
    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };
};
    return
    // return () => {
    //   ws.close();
    // };
  }, []);

  // funckja do oświeżania Batch monitor
  const refreshBatchMonitor = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  // hooki do obsługi przycisków
  const handleCleanImg = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to clean the images database?"
    );
    if (!confirmed) {
      return; // Anuluj operację, jeśli użytkownik kliknie "Cancel"
    }

    setLoading(true);
    try {
      const response = await axiosInstance.get(`${proxy}/api/img/clean`);
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSyncImages = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`${proxy}/api/sync/visuals/`, {
        syncVideos: false,
        folder: "",
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSyncVideos = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`${proxy}/api/sync/visuals/`, {
        syncVideos: true,
        folder: "",
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIntraSync = async () => {
    setLoading(true);
    try {
      setSyncStatuses([]); // Wyczyść stan synchronizacji przed rozpoczęciem nowej synchronizacji
      const response = await axiosInstance.post(`${proxy}/api/sync/intra`,{
        translateBusinessDesc
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCostingSync = async () => {
    setLoading(true);
    try {
      setSyncStatuses([]); // Wyczyść stan synchronizacji przed rozpoczęciem nowej synchronizacji
      const response = await axiosInstance.post(`${proxy}/api/sync/intra`, {isCosting: true});
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloneTable = async () => {
    if (!selectedTable) {
      console.error("No table selected");
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post(`${proxy}/api/db/clone-table`, {
        tableName: selectedTable,
        order: order,
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <Card className="shadow-sm mb-5">
        <Card.Header as="h3" className="text-center">
          Visuals synchronization
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-wrap justify-content-center gap-3">
            <Button
              variant="primary"
              size="lg"
              onClick={handleSyncImages}
              disabled={loading}
            >
              {loading ? "Processing..." : "Sync Images"}
            </Button>

            <Button
              variant="primary"
              size="lg"
              onClick={handleSyncVideos}
              disabled={loading}
            >
              {loading ? "Processing..." : "Sync Vidoes"}
            </Button>
            {/* 
                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={scanVideosToJson} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Scan VIDEOS and put to JSON'}
                </Button>

                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={handleAddFIlesToDatabase} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Add Files to DB'}
                </Button>

                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={handleSortImages} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Sort Images in DB'}
                </Button> */}
          </div>

          {/* Adding space for webhooks */}
          <ul className="mt-3">
            {visSyncStatuses.map((status, index) => (
              <li key={index}>
                {status.step} : {status.status}
                {status.status === "error" && <span> - {status.message}</span>}
              </li>
            ))}
          </ul>
          <div>
          {rcloneSyncStatus ? (
  <div>

    
    {rcloneSyncStatus.transferred && (

      <div>
        <h3>Sync Progress</h3>
        <h4>Transferred Data</h4>
        <p>Completed: {rcloneSyncStatus.transferred.completed}</p>
        <p>Total: {rcloneSyncStatus.transferred.total}</p>
        <p>Progress: {rcloneSyncStatus.transferred.percent}%</p>
        <p>Speed: {rcloneSyncStatus.transferred.rate}</p>
        <p>ETA: {rcloneSyncStatus.transferred.eta}</p>
      </div>
    )}
    
    {rcloneSyncStatus.checks && (
      <div>
        <h4>File Checks</h4>
        <p>Completed: {rcloneSyncStatus.checks.completed}</p>
        <p>Total: {rcloneSyncStatus.checks.total}</p>
        <p>Progress: {rcloneSyncStatus.checks.percent}%</p>
      </div>
    )}
    
    {rcloneSyncStatus.elapsedTime && (
      <div>
        <h4>Elapsed Time</h4>
        <p>{rcloneSyncStatus.elapsedTime}</p>
      </div>
    )}
    
    {rcloneSyncStatus.transferring && rcloneSyncStatus.transferring.length > 0 && (
      <div>
        <h4>Currently Transferring</h4>
        {rcloneSyncStatus.transferring.map((file, index) => (
          <div key={index} style={{ marginBottom: "8px" }}>
            <p>File: {file.name}</p>
            {file.progress ? (
              <>
                <p>Progress: {file.progress}%</p>
                <p>Size: {file.size}</p>
                <p>Speed: {file.rate}</p>
                <p>ETA: {file.eta}</p>
              </>
            ) : (
              <p>Status: {file.status}</p>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
) : (
  <p>No progress data available.</p>
)}

            </div>
        </Card.Body>
      </Card>

      <Card className="shadow-sm mb-5">
        <Card.Header as="h4" className="text-center">
          Database Syncronization
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-wrap justify-content-center gap-3">
            <Button
              variant="primary"
              size="lg"
              onClick={handleIntraSync}
              disabled={loading}
            >
              {loading ? "Processing..." : "Sync Intragroup with Cloud"}
            </Button>
          </div>
          
          <div className="d-flex flex-wrap justify-content-center gap-3 mt-2">
            <Form.Check
                type="switch"
                id="business-desc-trans-switch"
                label="translate Business Description ?"
                onChange={handleToggleBusinessDescTrans}
            />
          </div>
          <hr class="hr" />
          <div className="d-flex flex-wrap justify-content-center gap-3 mt-4">
            <Button
              variant="primary"
              size="lg"
              onClick={handleCostingSync}
              disabled={loading}
            >
              {loading ? "Processing..." : "Sync Costing with Cloud"}
            </Button>
          </div>
          

         
          <ul className="mt-3">
            {syncStatuses.map((status, index) => (
              <li key={index}>
                {status.step} : {status.status}
                {status.status === "error" && <span> - {status.message}</span>}
              </li>
            ))}
          </ul>
        </Card.Body>
      </Card>

      <Card className="shadow-sm">
        <Card.Header as="h4" className="text-center">
          Database Tables
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center">
          <Form.Group controlId="formTableSelect" className="w-50">
            <Form.Label>Select a table</Form.Label>
            <Form.Control
              as="select"
              value={selectedTable}
              onChange={(e) => setSelectedTable(e.target.value)}
              className="mb-3"
            >
              <option value="" disabled>
                Select a table
              </option>
              {dbTables.map((table, index) => (
                <option key={index} value={table}>
                  {table}
                </option>
              ))}
            </Form.Control>
            <Form.Check
              type="switch"
              id="under-maintenance-switch"
              label="From external to internal?"
              onChange={handleToggleOrder}
            />
          </Form.Group>

          <Button
            variant="primary"
            size="lg"
            onClick={handleCloneTable}
            disabled={loading || !selectedTable}
          >
            {loading ? "Processing..." : "Clone Table"}
          </Button>
        </Card.Body>
      </Card>

      <Card className="shadow-sm">
        <Card.Header as="h4" className="text-center">
          Maintenence switches
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center">
          <Form.Check
            type="switch"
            id="under-maintenance-switch"
            label="Under Maintenance"
            checked={isUnderMaintenance}
            onChange={handleToggle}
          />
        </Card.Body>
      </Card>
            <GptBatchMonitor refreshKey={refreshKey} />
    </div>
  );
}

export default AdminControl;
