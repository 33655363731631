import React from 'react';
import { useTranslation } from 'react-i18next';
import useLoadTranslations from '../../hooks/useLoadTranslations';

const TestPageClient = ({ lng = 'fr', namespace = 'landing_page' }) => {
  const { t } = useTranslation(namespace);
  const { isLoading, error } = useLoadTranslations(lng, namespace);

  if (isLoading) return <p>Loading translations...</p>;
  if (error) return <p>Failed to load translations.</p>;

  return (
    <div>
      <h1>{t('welcome')}</h1>
      <p>{t('description')}</p> 
    </div>
  );
};

export default TestPageClient;

