import proxy from '../setup/proxy';
import axiosInstance from "../setup/axiosInstance.js";

const ProductDataService = {
// FETCHING DATA
    getColumns: async () => {
        try {
            // set the endpoint
             const endpoint =`${proxy}/api/products/get-columns`;
            const response = await axiosInstance.get(endpoint);
            return response.data;
          } catch (error) {
            throw error;
          }
        },
     fetchBusinessCategories: async () => {
            try {
                const response = await axiosInstance.get(`${proxy}/api/products/unique-values-for-column?column=business_category`);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
     fetchCommercialCategories: async () => {
            try {
                const response = await axiosInstance.get(`${proxy}/api/products/unique-values-for-column?column=commercial_category`);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
    fetchProductDataCategories: async () => {
        try{
            const response = await axiosInstance.get(`${proxy}/api/products/get-product-data-categories`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getProductBySku: async (sku) => {
        try {
            const response = await axiosInstance.get(`${proxy}/api/products/get-data-with-business-desc?sku=${sku}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

// PARSING DATA        
    parseColumsToOptions: (data) => {
         // Map the data to the format react-select requires
         const options = Object.entries(data).map(([label, value]) => ({
            label: value, // Teraz wartość będzie etykietą
            value: label  // Klucz będzie wartością
          }));
                    
        // Filtruj opcje, aby usunąć element z value i label równym "SKU"
        const filteredOptions = options.filter(option => option.value !== 'SKU' && option.label !== 'SKU');
        return filteredOptions;  
    },

    
}

export default ProductDataService;