import React, { useState } from "react";
import './ProductCard.css';
import proxy from '../setup/proxy.js'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./ProductCard.css";


// context
import { useLandingPageContext } from "../context/LandingPageContext.js";


const ProductCard = ({ imageSrc, productName, productEan, productCode, productSize, sizeTag, pageKey, onClick, handleCheckboxChange, isChecked, price }) => {
    const { landingPageData } = useLandingPageContext();

    const priceType = landingPageData.landingPageData.price_type ? landingPageData.landingPageData.price_type  : "price";
    const currency = landingPageData.landingPageData.currency;

   
  
  // check product size
  if (productSize === null || productSize === 0 || productSize === " x  x  cm" || productSize === "0 x 0 x 0 cm")  productSize = "---";

  imageSrc = `${proxy}/img/main/250/${productCode}`;
  const defaultImageSrc = `${proxy}/content/compactor_default.jpg`;

  const [currentImageSrc, setCurrentImageSrc] = useState(imageSrc);



  return (
    <div className="product-card" onClick={onClick}>
      <input
        type="checkbox"
        className="product-checkbox form-check-input"
        checked={isChecked}
        onChange={(e) => {
          e.stopPropagation(); // Zapobiega propagacji zdarzenia
          handleCheckboxChange();
        }}
        onClick={(e) => e.stopPropagation()} // Zapobiega propagacji zdarzenia
      />
      <div  className="product-image d-flex justify-content-center p-0 m-0" style={{backgroundImage: `url(${defaultImageSrc})`}}>
      <LazyLoadImage
        src={currentImageSrc}
        alt={productName}
        effect="blur" // Efekt rozmycia przy ładowaniu obrazu
        beforeLoad={() => (
          <div
            style={{
              width: "250px",
              height: "250px",
              background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "loading 1.5s infinite",
            }}
          />
        )}
        onError={() => setCurrentImageSrc(defaultImageSrc)} // Zmiana obrazu w przypadku błędu
        placeholderSrc={defaultImageSrc} // Placeholder, gdy obraz się ładuje
        style={{padding: "0px", width: "100%", height: "100%"}}
        threshold={100}
      />
      </div>
      <div className="product-info">
        <h3 className="product-code">{productCode}</h3>
        {productEan && <h4 className="product-ean">EAN: {productEan}</h4>}
        <h4 className="product-name">{productName}</h4>
        {productSize !== "---" && (
          <p className="product-size">
            {sizeTag && <span>Size: {sizeTag} (</span>}
            {productSize}
            {sizeTag && <span>)</span>}
          </p>
        )}
        {price && (
          <p className="product-price">
            <span>
              {priceType}: {currency} {price}{" "}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
