// src/stores/translationStore.js
import { create } from 'zustand';

const useTranslationStore = create((set) => ({
  isLoading: false,
  isReady: false,
  clientLang: localStorage.getItem('clientLang') || 'en', // Domyślny język
  userLang: localStorage.getItem('userLang') || 'fr', // Domyślny język

  setLoading: (value) =>
    set((state) => {
      if (state.isLoading !== value) {

        return { isLoading: value };
      }
      return state; // Nie zmieniaj stanu, jeśli wartość jest taka sama
    }),
    setReady: (value) =>
      set((state) => {

        if (state.isReady !== value) {
          return { isReady: value };
        }
        return state;
      }),
  setUserLang: (value) =>
      set((state) => {
        if(state.userLang !== value) {

          localStorage.setItem('userLang', value);
          return { userLang: value };
        }
        return state;
      }),
  setClientLang: (value) =>
      set((state) => {
        if(state.clientLang !== value) {

          localStorage.setItem('clientLang', value);

          return { clientLang: value };
        }
        return state;
      }),      
}));

export default useTranslationStore;

