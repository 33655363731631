import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useTranslationStore from '../../stores/TranslationStore';

const TestPageAdmin = ({namespace = 'landing_page' }) => {
    const { t } = useTranslation(namespace);
    const isReady = useTranslationStore((state) => state.isReady);


    if (!isReady) return <p>Loading translations...</p>;
    

  return (
    <div>
      <h1>{t('welcome')}</h1>
      <p>{t('description')}</p> 
    </div>
  );

};

export default TestPageAdmin;

