// src/setup/axiosPublicInstance.js

import axios from 'axios';
import proxy from './proxy.js';


const token = process.env.LANDING_PAGE_PUBLIC_TOKEN

// Utwórz niestandardową instancję Axios
const axiosPublicInstance = axios.create({
  baseURL: proxy, // Zaktualizuj do swojego proxy
  headers: {
    'Content-Type': 'application/json',
  },
});

// Dodaj interceptor do dodawania tokena do każdego żądania
axiosPublicInstance.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



export default axiosPublicInstance;
