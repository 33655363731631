// src/utils/dataHelpers.js

/**
 * Sprawdza, czy obiekt jest pusty
 * @param {Object} obj 
 * @returns {boolean}
 */
export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  
  /**
   * Czyści dane, usuwając puste obiekty i obiekty, gdzie sku jest puste
   * @param {Object|Array} data 
   * @returns {Object|Array}
   */
  export const cleanData = (data) => {
    if (Array.isArray(data)) {
      return data.filter(item => !isEmptyObject(item) && item.sku !== "");
    } else if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].filter(item => !isEmptyObject(item) && item.sku !== "");
        } else if (typeof data[key] === 'object' && data[key] !== null) {
          data[key] = cleanData(data[key]);
        }
      }
      return data;
    }
    return data;
  };
  
  /**
   * Waliduje datę i zwraca ją w odpowiednim formacie
   * @param {string} date 
   * @returns {string}
   */
  export const checkDateValidity = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    if (!date) return "";
    if (date === "1969-12-31T23:00:00.000Z") return "";
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  export const normalizeString = (input) => { 
    if (typeof input !== 'string') {
      throw new Error('Input must be a string');
    }
  
    return input
      .trim()         // Usuń białe znaki z początku i końca
      .toLowerCase()  // Zamień wszystkie litery na małe
      .replace(/\s+/g, '_'); // Zamień wszystkie spacje na "_"

  };
  