import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import Home from "../pages/admin/Home";
import DescriptionsEditor from "../pages/admin/DescriptionsEditor";
import ImageOrder from "../pages/admin/ImageOrder";
import ShowImagesResize from "../pages/admin/BrowseVisuals";
import Login from "../components/Login";
import GetProductsData from "../pages/admin/GetProductsData";
import ProductsBrowser from "../pages/admin/ProductsBrowser";
import AdminControl from "../pages/admin/AdminControl";
import SkuEanConverter from "../pages/admin/SkuEanConverter";
import UpdateProductsData from "../pages/admin/UpdateProductsData";
import DescriptionsBulkGeneration from "../pages/admin/DescriptionsBulkGeneration";
import DescriptionGetForm from "../pages/admin/DescriptionsGetForm";
import LandingPageEditor from "../pages/admin/LandingPageEditor";
import TestPageAdmin from "../pages/admin/TestPageAdmin";
import TestPageClient from "../pages/admin/TestPageClient";
import Changelog from "../pages/admin/Changelog";
import ProductDataPage from "../pages/admin/ProductDataPage";

import UsersEditor from "../pages/admin/UsersEditor";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const AdminRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);
  console.log(isLoggedIn);

  if (!isLoggedIn) return <Navigate to="/login" />;

  return (
    <AdminLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/descriptions-editor" element={<DescriptionsEditor />} />
        <Route path="/users-editor" element={<UsersEditor />} />
        <Route path="/" element={<Home />} />
        <Route path="/image-order" element={<ImageOrder />} />
        <Route path="/image-show" element={<ShowImagesResize />} />
        <Route path="/get-products-data" element={<GetProductsData />} />
        <Route path="/update-products-data" element={<UpdateProductsData />} />
        <Route path="/products-browser" element={<ProductDataPage />} />
        <Route path="/sku-ean-converter" element={<SkuEanConverter />} />
        <Route path="/descriptions-editor" element={<DescriptionsEditor />} />
        <Route path="/descriptions-export-excel" element={<DescriptionGetForm />} />
        <Route path="/descriptions-bulk-generation" element={<DescriptionsBulkGeneration />} />
        <Route path="/landing-page-editor/:page_key?" element={<LandingPageEditor />} />
        <Route path="/users-editor" element={<UsersEditor />} />
        <Route path="/admin-control" element={<AdminControl />} />
        <Route path="/login" element={<Login />} />
        <Route path="/changelog" element={<Changelog />} />
        <Route path="/test-page-admin" element={<TestPageAdmin />} />
        <Route path="/test-page-client" element={<TestPageClient />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AdminLayout>
  );
};

export default AdminRoutes;
