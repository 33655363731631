// src/components/utilities/ImageWithCheck.js

import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../setup/axiosInstance.js';
import UseWindowSize from './UseWindowSize.js';
import VisualBrowser from './VisualBrowser';
import './ShowProductVisuals.css';
import proxy from '../setup/proxy';
import { CategoryDivider } from "../components/visual/headers.js";
import { DownloadButton } from "./Buttons";

import useLoadTranslations from '../hooks/useLoadTranslations';

import { useTranslation } from 'react-i18next';
import useTranslationStore from '../stores/TranslationStore';

const ShowProductImagesWithResize = ({ selectedSKU, showResize = true, defaultSize = 0, showDownloadButton = false, targetTrans = 'common'}) => {
    const [images, setImages] = useState([]);
    const [imgSize, setImgSize] = useState(600);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false); 
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentItems, setCurrentItems] = useState([]); // To track current items being viewed in the overlay

// TRANSLATIONS SETUP
    const { t } = useTranslation(targetTrans);
    const isReady = useTranslationStore((state) => state.isReady);


    if(defaultSize > 0) {
        setImgSize(defaultSize);
    }

    let dynamicUrl = proxy + `/img/dynamic/${imgSize}/`;
    let tumbnailUrl = proxy + `/img/dynamic/250/`;
    let { width } = UseWindowSize();
    let dragWidth = (Math.floor(width / 12) * 8) - 50;
    let tileWidth = Math.floor(dragWidth / 5);

    const fetchImages = useCallback(async () => {
        if (selectedSKU) {
            try {
                const response = await axiosInstance.get(`${proxy}/img/get-all/${selectedSKU}`);
                const sortedImages = response.data.sort((a, b) => a.visual_order - b.visual_order);
                setImages(sortedImages.map(image => ({
                    ...image,
                    src: image.file_type === 'image' ? dynamicUrl + image.relative_file_path : proxy + `/api/videos/stream/` + image.relative_file_path,
                    thumbnail: image.file_type === 'image' ? tumbnailUrl + image.relative_file_path : proxy + image.thumbnail,
                })));
            } catch (error) {
                console.error('Error fetching images:', error);
                setImages([]);
            }
        }
    }, [selectedSKU, dynamicUrl, proxy, imgSize]);

    useEffect(() => {
        fetchImages();
    }, [fetchImages]);


    const openOverlay = (index, items) => {
        setCurrentIndex(index);
        setCurrentItems(items);
        setIsOverlayVisible(true);
    };

    const closeOverlay = () => {
        setIsOverlayVisible(false);
    };

    const PhotoTile = ({ item, index, items }) => {
        const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    
        if (!item) return null; // Zabezpieczenie przed undefined item
    
        const handleClick = () => {
            if (item.file_type === 'video') {
                setIsVideoLoaded(true);
            }
            openOverlay(index, items);
        };
    
        return (
            <div className="show-vis-tiles-img-body" onClick={handleClick}>
                {item.file_type === 'image' ? (
                    <img
                        src={item.src}
                        alt={item.filename}
                        className="show-vis-tiles-img"
                    />
                ) : (
                    <>
                        {!isVideoLoaded ? (
                            <img
                                src={item.thumbnail}
                                alt={`${item.filename} thumbnail`}
                                className="show-vis-tiles-img"
                            />
                        ) : (
                            <video width={tileWidth} className="show-vis-tiles-img" controls>
                                <source src={item.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </>
                )}
                <p style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
                    {item.filename}
                </p>
            </div>
        );
    };

    const photoItems = images.filter(item => item.file_type === 'image');
    const videoItems = images.filter(item => item.file_type === 'video');

    console.log("videoItems", videoItems);

    return (
        <>
            {showResize && (
            <>
                <span>{t('choose_image_size')}</span>
                <select
                    className="tiles-show-size-input"
                    defaultValue="600"
                    onChange={(e) => setImgSize(e.target.value)}
                >
                    <option value="250">250 px</option>
                    <option value="400">400 px</option>
                    <option value="600">600 px</option>
                    <option value="0">Original</option>
                </select>
            </>
            )}

            {photoItems.length > 0 && (
                <>
                    {/* <div className="show-vis-header">Photos <span className='show-vis-text-small'>({photoItems.length })</span></div> */}
                        <CategoryDivider category={t('photos')} additional_text={`(${photoItems.length}) `}/>
                      {showDownloadButton && <div className="d-flex d-100 justify-content-center mb-4">
                       <DownloadButton text={'download_visual_content'} skus={[selectedSKU]} />
                        </div> }
                    <div className="show-vis-tiles-show-container">
                        {photoItems.map((image, index) => (
                            <PhotoTile key={image.id} item={image} index={index} items={photoItems} />
                        ))}
                    </div>
                </>
            )}

            {videoItems.length > 0 && (
                <>
                    {/* <div className="show-vis-header" style={{ marginTop: "50px" }}>Videos <span className='show-vis-text-small'>({videoItems.length })</span></div> */}
                    <CategoryDivider category="Video" additional_text={`(${videoItems.length}) `}/>
                    <div className="show-vis-tiles-show-container">
                        {videoItems.map((image, index) => (
                            <PhotoTile key={image.id} item={image} index={index} items={videoItems} />
                        ))}
                    </div>
                </>
            )}

            <VisualBrowser
                isVisible={isOverlayVisible}
                images={currentItems}
                currentIndex={currentIndex}
                onClose={closeOverlay}
            />
        </>
    );
};

export { ShowProductImagesWithResize };
