// src/store/DescriptionStore.js

import { create } from "zustand";

const useDescriptionStore = create((set) => ({
  globalData: {
    sku: "",
    productData: {},
    commercialCategory: {},
    additionalInfo: {},
  },
  descriptions: {}, // Opisy (zorganizowane według target pages i languages)
  targetPages: [], // Lista target pages
  languages: [], // Lista languages

  queryData: null,

  setGlobalData: (key, newData) =>
    set((state) => ({
      globalData: {
        ...state.globalData, // Zachowaj istniejące dane globalData
        [key]: {
          ...state.globalData[key], // Zachowaj istniejące dane dla tego klucza
          ...(newData || {}), // Połącz nowe dane
        },
      },
    })),

  setQueryData: (newData) =>
    set(() => ({
      queryData: newData || null, // Jeśli `newData` jest pusty, ustaw `null`
    })),

  // Funkcja do ustawienia danych z backendu
  setDescriptions: (descriptions) =>
    set(() => ({
      descriptions: descriptions || {}, // Jeśli `descriptions` jest pusty, ustaw pusty obiekt
    })),

  setTargetPages: (targetPages) =>
    set(() => ({
      targetPages: targetPages || [], // Jeśli `targetPages` jest pusty, ustaw pustą tablicę
    })),

  setLanguages: (languages) =>
    set(() => ({
      languages: languages || [], // Jeśli `languages` jest pusty, ustaw pustą tablicę
    })),

  // Funkcja do aktualizacji konkretnego opisu
  updateDescription: (targetPage, language, key, value) =>
    set((state) => {
      const updatedDescriptions = { ...state.descriptions };
      if (!updatedDescriptions[targetPage]) {
        updatedDescriptions[targetPage] = {};
      }
      if (!updatedDescriptions[targetPage][language]) {
        updatedDescriptions[targetPage][language] = {};
      }

      updatedDescriptions[targetPage][language][key] = value;

      return { descriptions: updatedDescriptions };
    }),
}));

export default useDescriptionStore;
