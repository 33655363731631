import React from "react";
import { useParams } from "react-router-dom";

import LandingPageNavigation from "../components/navigation/LandingPageNavigation";
import Footer from "../components/Footer";
import { LandingPageProvider } from "../context/LandingPageContext";

import useTranslationStore from "../stores/TranslationStore";
import { useTranslation } from "react-i18next";
import useLoadTranslations from '../hooks/useLoadTranslations.js';

const LandingPageLayout = ({ children }) => {
  // const { clientLang } = useTranslationStore();
  // const namespace = 'landing_page';
  // useLoadTranslations(clientLang, namespace); // Hook automatycznie zarządza stanem



  return (
    <LandingPageProvider>
      {/* <LandingPageNavigation /> */}
      <div className="public-content">{children}</div>
      <Footer />
    </LandingPageProvider>
  );
};

export default LandingPageLayout;
