import React from 'react';
import useTranslationStore from "../../stores/TranslationStore.js";
import proxy from '../../setup/proxy.js';
import './LanguageSelector.css';

const LanguageSelector = ({ selectedLanguage, onChangeLanguage }) => {
  const languages = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
    { code: "it", label: "Italiano" },
    { code: "es", label: "Español" },
    { code: "pl", label: "Polski" },
  ];

  return (
    <div className="language-selector">
      {languages.map((language) => (
        <button
          key={language.code}
          onClick={() => onChangeLanguage(language.code)}
          className={`language-btn ${selectedLanguage === language.code ? 'selected' : ''}`}
          title={language.label} // Opcjonalnie: wyświetl nazwę języka jako podpowiedź
        >
          <img
            src={`${proxy}/content/flags/flag-${language.code}.jpg`} // Ścieżka do plików flag
            alt={language.code}
            className="language-flag"
          />
        </button>
      ))}
    </div>
  );
};

export default LanguageSelector;
