// src/pages/DescriptionsEditor.js

import React, { useState, useEffect, useCallback, useContext, useRef } from "react";

// compononents
import DescriptionsGeneral from "../../components/descriptions/DescriptionsGeneral.js";
import Descriptions from "../../components/descriptions/Descriptions.js";
import SKUlist from "../../components/SKUlist.js";
import { getSkusListFromIntragroup } from "../../services/skusService.js";


// services
import {
  fetchGlobalData,
  fetchDescriptionData,
  fetchQueryData,
  saveDescriptionData,
} from "../../services/descriptionService.js";

// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../../components/descriptions/Descriptions.css"; 

// Import AuthContext 
import { AuthContext } from '../../context/AuthContext.js';

// import zustand store
import useDescriptionStore from "../../stores/DescriptionStore.js";

const DescriptionEditor = () => {
 // const [data, setData] = useState(null);
  const [sku, setSelectedSKU] = useState("RAN3066");
  const [skuList, setSkuList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedTargetPage, setSelectedTargetPage] = useState("product");
  const [refreshFlag, setRefreshFlag] = useState(false);

  const {descriptions, updateDescription, languages, targetPages, setGlobalData, setQueryData, setDescriptions, setTargetPages, setLanguages } = useDescriptionStore();

  const { roles } = useContext(AuthContext);
  const hasAccess = (role) => roles.includes(role);

  // Set the variables with data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [globalData, skus] = await Promise.all([
          fetchGlobalData(sku),
          getSkusListFromIntragroup()
        ]);
          // Rozbij dane na odpowiednie klucze
      setGlobalData('productData', globalData.productData);
      setGlobalData('commercialCategory', globalData.commercialCategory);
      setGlobalData('additionalInfo', globalData.additionalInfo);



      console.log("descriptions", descriptions);

      setSkuList(skus);
      } catch (error) {
        console.error("Error fetching SKU list:", error);
      }
    };

    fetchData();
  }, [ setGlobalData, setQueryData, setDescriptions, setSkuList, sku]);

  useEffect(() => {
    const updateDescriptionData = async () => {
      try {
        const data = await fetchDescriptionData(sku)
        // add description data
        const { descriptions, targetPages, languages } = data;
  
        setDescriptions(descriptions);
        setTargetPages(targetPages);
        setLanguages(languages);
      } catch (error) {
        console.error("Error fetching query data:", error);
      }
    };
    updateDescriptionData();
  }, [refreshFlag, sku, setDescriptions, setTargetPages, setLanguages]);

  // handles for change the data from the forms 
  const handleGlobalData = (key) => (e) => {
    const { name, value } = e.target;
    setGlobalData(key, { [name]: value }); // Aktualizuj dane w odpowiednim kluczu
  };

  const handleQueryData = (e) => {
    const { name, value } = e.target;
    setQueryData({ [name]: value });
  }

  const handleDescriptionData = (targetPage, language, key, value) => {
    updateDescription(targetPage, language, key, value);
  };



  const handleSKUSelect = (sku) => {
    setSelectedSKU(sku);
  };

  const refreshFlagToggle = () => {
    setRefreshFlag(!refreshFlag);
  };


  return (
    <>
      <div className="row d-flex flex-columns" style={{ minHeight: "90vh" }}>
        <div className="col-2">
          <SKUlist
            onSKUSelect={handleSKUSelect}
            showVisualsQuantity={false}
            showFilterPhotosAvailable={false}
            showFilterVideosAvailable={false}
            skuList={skuList}
            showFilterIntragroupProducts={true}
          />
        </div>
        <div className="col-10">
          <div className="container mt-4 ">
            <DescriptionsGeneral
              sku={sku}
              handleGlobalData={handleGlobalData}
            />
            <div className="mt-4">
              <Descriptions
                sku={sku}
                handleDescriptionData={handleDescriptionData}
                refreshFlagToggle={refreshFlagToggle}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionEditor;
