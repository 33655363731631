import React, { useEffect, useState } from "react";
import ProductDataTable from "../../components/ProductDataTable";
import proxy from "../../setup/proxy";

import "./ProductDataPage.css";
import SKUlist from "../../components/SKUlist";

import ProductDataService from "../../services/ProductDataService";
import { use } from "react";
import { getSkusListFromIntragroup } from "../../services/skusService";

// translatiopns
import { useTranslation } from "react-i18next";

// styles
import { Row, Col, Card } from "react-bootstrap";

const FILTERS_KEY = "productDataFilters";

const Filters = ({ filters, toggleFilter }) => {
  const [filtersData, setFiltersData] = useState({});

  // translations setup
  const { t } = useTranslation("common");

  // Pobieranie grup filtrów
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ProductDataService.fetchProductDataCategories();
        setFiltersData(data);
      } catch (err) {
        console.error("Unable to fetch filters data. Please try again.");
      }
    };

    fetchData();
  }, []);

  if (!filtersData || Object.keys(filtersData).length === 0)
    return <p>Loading...</p>;



  return (
<Card className="d-flex align-items-left p-3 mt-3">
  <h4 className="w-100 text-align-left">{t("filters")}</h4>
  <div className="filter-switches d-flex justify-content-left flex-wrap">
    {Object.keys(filtersData).map((filterKey) => (
      <button
        key={filterKey}
        className={`btn btn-sm me-2 mb-2 ${
          filters[filterKey] ? "btn-primary" : "btn-secondary"
        }`} // Zmiana koloru w zależności od stanu
        onClick={() => toggleFilter(filterKey)}
      >
        {t(filterKey)} {/* Możesz użyć tłumaczeń */}
      </button>
    ))}
  </div>
</Card>
  );
};

const ProductTablePage = ({ sku }) => {
  const [productData, setProductData] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(false); // Dotyczy tylko tabeli
  const [error, setError] = useState(null);
  const [filtersData, setFiltersData] = useState({});
  const [filters, setFilters] = useState({});
  const [skuList, setSkuList] = useState([]);
  const [selectedSKU, setSelectedSKU] = useState(null);

    // translations setup
    const { t } = useTranslation("common");

  const handleSKUSelect = (sku) => {
    setSelectedSKU(sku);
  };

  // Pobranie danych produktu
  useEffect(() => {
    if (!selectedSKU) return; // Nie rób nic, jeśli SKU nie jest wybrane

    const fetchData = async () => {
      try {
        setIsTableLoading(true); // Ustaw stan ładowania tylko dla tabeli
        const data = await ProductDataService.getProductBySku(selectedSKU);
        setProductData(data);
      } catch (err) {
        setError("Unable to fetch product data. Please try again.");
      } finally {
        setIsTableLoading(false);
      }
    };

    fetchData();
  }, [selectedSKU]);

  // Pobranie danych filtrów
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const skus = await getSkusListFromIntragroup();
        setSkuList(skus);

        const data = await ProductDataService.fetchProductDataCategories();
        setFiltersData(data);

        // Inicjalizacja stanu filtrów z domyślnymi wartościami true
        const initialFilters = Object.keys(data).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        );
        setFilters(initialFilters);
      } catch (err) {
        console.error("Unable to fetch filters data:", err);
      }
    };

    fetchFilters();
  }, []);

  // Funkcja do przełączania stanu filtru
  const toggleFilter = (filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey],
    }));

    localStorage.setItem(FILTERS_KEY, JSON.stringify(filters));
  };

  // Zarządzanie kluczami do pokazania na podstawie aktywnych filtrów
  const keysToShow = [
    "sku", // SKU zawsze wyświetlane
    ...Object.entries(filters)
      .filter(([key, isActive]) => isActive) // Pobierz aktywne filtry
      .flatMap(([key]) => filtersData[key] || []), // Mapuj do kluczy z filtersData
  ];



    return (
        <Row className="mx-3">
          {/* Lewy panel z listą SKU */}
          <Col xs={12} md={2} className="mb-3">
            <SKUlist
              onSKUSelect={handleSKUSelect}
              showVisualsQuantity={false}
              showFilterPhotosAvailable={false}
              showFilterVideosAvailable={false}
              skuList={skuList}
              showFilterIntragroupProducts={true}
            />
          </Col>
      
          {selectedSKU ? (
            /* Prawy panel z filtrami i tabelą */
            <Col xs={12} md={10} className="mt-3">
              <Card className="p-3">
                <Row>
                  {/* Lewy blok z filtrami i obrazem */}
                  <Col xs={12} lg={3} className="mb-3">
                    <Filters filters={filters} toggleFilter={toggleFilter} />
                    <img
                      src={`${proxy}/img/main/250/${selectedSKU}`}
                      alt="Product"
                      className="img-fluid mt-3"
                    />
                  </Col>
      
                  {/* Prawy blok z tabelą */}
                  <Col xs={12} lg={9}>
                    {isTableLoading ? (
                      <p>Loading data...</p> // Możesz dodać spinner lub placeholder
                    ) : (
                      <ProductDataTable
                        data={productData}
                        keysToShow={keysToShow}
                        isTableLoading={isTableLoading}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          ) : (
            <Col xs={12} md={10} className="mt-3 aligh-items-center justify-content-center d-flex mt-3 pt-3">
              <h2>Please select a SKU to view the data.</h2>
            </Col>
          )}
        </Row>
      );
      
};

export default ProductTablePage;
