import { useQuery } from '@tanstack/react-query';
import { fetchLandingPageData } from '../services/landingPageService';


const useLandingPageData = (pageKey) => {
  return useQuery({
    queryKey: ['landingPage', pageKey],
    queryFn: () => fetchLandingPageData(pageKey),
    staleTime: 5 * 60 * 1000, // Cache przez 5 minut
    cacheTime: 10 * 60 * 1000, // Dane w cache przez 10 minut
    retry: 2, // Automatyczna próba ponowienia w razie błędu
    refetchOnWindowFocus: false, // Nie odświeżaj danych po odzyskaniu focusa
  });
};

export default useLandingPageData;
