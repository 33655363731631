const LandingPageHeader = ({ landingPageData }) => {
  const checkDateValidity = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    if (!date) return "";
    if (date === "1969-12-31T23:00:00.000Z") return "";
    const newDate = new Date(date).toLocaleDateString("en-GB", options);

    return newDate;
  };

  landingPageData = landingPageData.landingPageData;


  return (
    <div className="landing-page-header-container">
      <div className="landing-page-header-img">
        <div className="landing-page-header-text">
          <h2>{landingPageData.project_name}</h2>
          <p>
            {landingPageData.client_name && (
              <>To: {landingPageData.client_name}</>
            )}
          </p>
          <p>
            {checkDateValidity(landingPageData.offer_date) && (
              <>Offer date: {checkDateValidity(landingPageData.offer_date)}</>
            )}
          </p>
          <p>
            {checkDateValidity(landingPageData.exp_date) && (
              <>
                Expiration date: {checkDateValidity(landingPageData.exp_date)}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingPageHeader;
