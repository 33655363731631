import proxy from '../setup/proxy.js';
import axiosInstance from '../setup/axiosInstance.js';
import { toast } from 'react-toastify';

const DownloadImages = async (skus, size, t) => {

    try {
     toast(t('download_preparation_popup'));
      const response = await axiosInstance.post(`${proxy}/img/download/`, { skus, size }, {
        responseType: 'blob' // Odbierz odpowiedź jako blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'images.zip'); // Ustaw nazwę pliku
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.success(t('download_ready_popup'));
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }

  export { DownloadImages };