import axiosInstance from "../setup/axiosInstance";
import proxy from "../setup/proxy";

const fetchGlobalData = async (sku) => {
  try {
    const response = await axiosInstance.get(
      `${proxy}/api/products/desc/get-global-data/?sku=${sku}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching description data:", error);
  }
};

const fetchSingleDescription = async (sku, lang, target) => {
  try {
    const response = await axiosInstance.get(
      `${proxy}/api/products/desc/get-single/`,
      {
        params: {
          sku,
          lang,
          target
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching description data:", error);
  }
};

const fetchDescriptionData = async (sku) => {
  try {
    const response = await axiosInstance.get(
      `${proxy}/api/products/desc/get-all-for-sku/`,
      {
        params: {
          sku
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching description data:", error);
  }
};

const fetchQueryData = async (sku, lang, target) => {
  try{
    const response = await axiosInstance.get(
      `${proxy}/api/products/desc/get-query-data`,
      {
        params: {
          sku,
          lang,
          target
        },
      }
    );
    return response.data;
  }catch(error){
    console.error("Error fetching query data:", error);
  }

}

const saveDescriptionData = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${proxy}/api/products/desc/save-single`,
      {
        data,
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching description data:", error);
  }
};

const generateContent = async (sku, query) => {
  try {
    const response = await axiosInstance.post(
      `${proxy}/api/desc/${sku}/descriptions/generate`,
      {
        query,
      }
    ); // Nagłówki przekazywane jako osobny argument w axios
    return response;
  } catch (error) {
    console.error("Error generating content:", error);
  }
};

const generateKeywords = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${proxy}/api/products/desc/generate-keywords`,
      {
        data,
      }
    ); // Nagłówki przekazywane jako osobny argument w axios
    return response;
  } catch (error) {
    console.error("Error generating content:", error);
  }
}

const generateDescription = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${proxy}/api/products/desc/generate-description`,
      {
        data,
      }
    ); 
    return response;
  } catch (error) {
    console.error("Error generating content:", error);
  }
};

export {
  fetchGlobalData,
  fetchDescriptionData,
  saveDescriptionData,
  generateContent,
  fetchQueryData,
  fetchSingleDescription,
  generateKeywords,
  generateDescription
};
