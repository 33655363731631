import React from "react";
import { Link } from 'react-router-dom';

// translations hook and store
import { useTranslation } from 'react-i18next';
import  useTranslationStore from '../../stores/TranslationStore.js';

function Home() {
  //translations setup
  const {t} = useTranslation('common');
  const isReady = useTranslationStore((state) => state.isReady);
  if(!isReady) return <p>Loading translations...</p>;
 

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
      <div className="d-flex flex-column align-items-center text-center">
        <h1 className="mb-3">{t('welcome_message')}</h1>
        <Link to="/changelog" className="text-muted">version 0.2.0</Link>
      </div>
    </div>
  );
}

export default Home;

