import proxy from '../setup/proxy.js';
import axiosInstance from '../setup/axiosInstance.js';

const getAllSkusList = async () => {

        try {
        const response = await axiosInstance.get(`${proxy}/api/products/get-sku-list`);
        return response.data;
    //   setSelectedSKU(selectedSku);
        } catch (error) {
            throw new Error(`Error fetching SKU list: ${error}`);
        }
}

const getSkusListFromIntragroup = async () => {
    try {
        const response = await axiosInstance.get(`${proxy}/api/products/get-sku-list-intra`);
        return response.data;
    } catch (error) {
        throw new Error(`Error fetching SKU list: ${error}`);
    }
}

  const GetLandingPageSkus = async (pageKey) => {
    try {
      const response = await axiosInstance.post(`${proxy}/api/landing-page/get-sku-list`, { page_key: pageKey });
      const skuArray = response.data;
      const skuObjects = skuArray.map(sku => ({ sku }));
      return(skuObjects);
    } catch (error) {
      console.log(error);
    }
  }




export { getAllSkusList,getSkusListFromIntragroup, GetLandingPageSkus };
