// src/components/ProductDataTable.js

import React from "react";
import { useTranslation } from "react-i18next";
import useTranslationStore from "../stores/TranslationStore";
import useLoadTranslations from "../hooks/useLoadTranslations";
import { Card } from "react-bootstrap";


import "./ProductDataTable.css";
import { use } from "react";


const ProductDataTable = ({ data, keysToShow = [], isTableLoading}) => {
    const { t } = useTranslation("landing_page");
    const isReady = useTranslationStore((state) => state.isReady);
    const userLang = useTranslationStore((state) => state.userLang);

    useLoadTranslations(userLang, "landing_page");

  
    if (!isReady) return <p>Loading translations...</p>;
    if (!data) {
        return;
        }

    // Funkcja do formatowania wartości na podstawie klucza
const formatValue = (key, value) => {
    //  if (value === null || value === 0 || value < 0.00000001) return null;
    
      switch (key) {
        case "business_description":
        case "business_description-en":
        case "business_description-fr":
        case "business_description-de":
        case "business_description-es":
        case "business_description-it":
        case "business_description-pl":
          return value?.split("\n").map((line, idx) => (
            <span key={idx}>
              {line}
              <br />
            </span>
          ));
        case "product_gross_weight_with_packaging":
        case "product_net_weight_without_packaging":
          return `${value} kg`;
        case "quantity_in_pack":
          if (value < 2 || value === "" || value === null) {
            return "Single";
          }
          return parseInt(value);
        case "pcb":
          return `${parseInt(value)} pcs`;
        default:
          return value;
      }
    };
    

    const renderRow = (t, key, value) => {
        if (value === null || value === 0 || value < 0.00000001) return null;
      
        const displayKey = key.startsWith("business_description_")
          ? "business_description"
          : key;
        const displayValue = formatValue(key, value);
      
        return (
          <tr key={key}>
            <th scope="row">{t(displayKey)}</th>
            <td>{displayValue}</td>
          </tr>
        );
      };
  
    const filteredKeysToShow = keysToShow.length
      ? keysToShow.filter(
          (key) =>
            data[key] !== null &&
            data[key] !== undefined &&
            data[key] !== "" &&
            data[key] !== " x  x  cm" &&
            data[key] !== 0
        )
      : Object.keys(data); // Wyświetl wszystkie dane, jeśli brak zdefiniowanych kluczy
  

    return (
    <Card className="d-flex align-items-center mt-3 p-2">

      <table className="table table-sm  table-light p-3 w-75 table-product-data mt-3">
        <thead>
          <tr>
            <th scope="col" style={{width:"30%"}}></th>
            <th scope="col" className="w-auto">{t("product_data")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredKeysToShow.map((key) =>
            renderRow(t, key, data[key])
          )}
        </tbody>
      </table>
    </Card>
    );
  };
  

  export default ProductDataTable;