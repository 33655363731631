import React, { useState, useEffect, useRef } from 'react';
import { Button,} from 'react-bootstrap';
import './SelectedProductsDropdown.css';
import { useTranslation } from 'react-i18next';

const SelectedProductsDropdown = ({ selectedSkus, handleAskForProducts, triggerHighlight, nameSpace }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const dropdownRef = useRef(null);

  const { t } = useTranslation(nameSpace);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
      setIsHighlighted(true);
      const timer = setTimeout(() => {
        setIsHighlighted(false);
      }, 500); // 0.5 sekundy

      return () => clearTimeout(timer);
  }, [triggerHighlight]);

  return (
    <div
      className={`shadow selected-products-dropdown ${showDropdown ? 'show' : ''}`}
    >
       <div className={`dropdown-toggle-btn ${isHighlighted ? 'highlighted' : ''}`} onClick={toggleDropdown}>
        {t('selected_products')} ({selectedSkus.length})
      </div>
      <div className="dropdown-menu-kurwa " ref={dropdownRef}>
        <Button variant="primary" onClick={handleAskForProducts} className="shadow mb-4">
        {t('ask_for_quotation')}
        </Button>

     
        <ul className="selected-products-list list-group list-group-flush list-group-numbered">
          {selectedSkus.map((sku, index) => (
            <li key={index} className="list-group-item d-flex justify-content-center align-items-start col">
                <div className="fw-bold mx-2 row">
                {sku}
                </div>        
            </li>
          ))}
        </ul>

      </div>
    </div>
  );
};

export default SelectedProductsDropdown;
