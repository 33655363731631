import React, { useState } from "react";
import { loginLandingPage } from "../../services/authService";
import { toast } from "react-toastify";
import { Col, Row, Card, Button, Container } from "react-bootstrap";
import proxy from '../../setup/proxy.js';

// languiage
import LanguageSelector from "../utilities/LanguageSelector.js";
import useTranslationStore from "../../stores/TranslationStore.js";
import { useTranslation } from 'react-i18next';

const PasswordInput = ({ setLoggedIn, pageKey }) => {
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const { clientLang, setClientLang } = useTranslationStore();
  const { t } = useTranslation('landing_page');

  const handleLanguageChange = (lang) => {
    setClientLang(lang);
  };

  const isReady = useTranslationStore((state) => state.isReady);

const lang = clientLang;

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handleLogin = async () => {
    try {
      const response = await loginLandingPage(password, pageKey);
      console.log("response", response);
      if (response.data.success) {
        console.log("success");
        toast.success("Logged in successfully");
        setLoggedIn(true);
      } else {
        toast.error("Invalid password");
        console.log("error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    
    <Container className="d-flex flex-column align-items-center">
    
     <img
            src={`${proxy}/content/logos/compactor-logo.png`}
            className="nav-bar-top-logo"
            alt="Compactor logo"
          />
      <Card className="m-5" style={{ maxWidth: "600px" }}>
        <Card.Header>
          <h4 className="text-center">{t('enter_landing_page_pass')}</h4>
        </Card.Header>
        <Card.Body>
        <Col className="d-flex flex-column align-items-center">
          <Row md={9}>
            <span className="text-muted text-center px-3 mb-3">{t('landing_page_client_pass_info')}</span>
          </Row>

          <Row>
            <div className="input-group">
              <input
                type={isVisible ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter password"
                className="form-control"
              />
              <button
                type="button"
                onClick={() => setIsVisible((prev) => !prev)}
                className="btn btn-outline-secondary btn-sm"
              >
                {isVisible ? t('hide_password') : t('show_password')}
              </button>
            </div>
          </Row>
          <Row className="mt-2 justify-content-center">
            <button
              type="button"
              onClick={() => handleLogin(password)}
              className="btn btn-primary mt-2"
            >
              {t('submit')}
            </button>
          </Row>
        </Col>
        </Card.Body>
        
      </Card>
      <LanguageSelector selectedLanguage={lang} onChangeLanguage={handleLanguageChange} /> 
    </Container>
  );
};

export default PasswordInput;
