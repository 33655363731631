import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import ImageWithCheck from "../utilities/ImageWithCheck";
import { ProductTable } from "../utilities/Tables";
import { DownloadButton } from "../Buttons";
import {ShowProductImagesWithResize} from '../ShowProductVisuals'; // Import brakującego komponentu
import proxy from "../../setup/proxy";
import './ProductModal.css';
import { useTranslation } from 'react-i18next';
import useTranslationStore from '../../stores/TranslationStore';

const ProductModal = ({ showModal, handleCloseModal, product, landingPageData, skuList, handleCheckboxChange,  handleAskForProducts}) => {
  const { t } = useTranslation("landing_page");
  const isReady = useTranslationStore((state) => state.isReady);

  if (!isReady) return <p>Loading translations...</p>;

  const skus = JSON.parse(landingPageData.landingPageData.skus);

  // Znajdowanie ceny produktu dla wybranego SKU
  const productPrice = skus.find(item => item.sku === product.sku)?.product_price || ``;
  const productName = (product) => {
    if (!product.business_description) {
      return "";
    }
    return product.business_description.split("\n")[0];
  };

  const productSize = (product) => {
    let size = "";
    if (
      product.product_s_length_cm_longueur === null &&
      product.product_s_width_cm_largeur === null &&
      product.product_s_height_cm_hauteur === null
    ) {
      return "---";
    }
    if (
      product.product_s_length_cm_longueur !== null &&
      product.product_s_length_cm_longueur !== 0
    ) {
      size += `${product.product_s_length_cm_longueur} x `;
    }
    if (
      product.product_s_width_cm_largeur !== null &&
      product.product_s_width_cm_largeur !== "0"
    ) {
      size += `${product.product_s_width_cm_largeur} x `;
    }
    if (
      product.product_s_height_cm_hauteur !== null &&
      product.product_s_height_cm_hauteur !== 0
    ) {
      size += `${product.product_s_height_cm_hauteur}`;
    }
    size += " cm";
    return size;
  };

  console.log("product", product);
  console.log("landingPageData", landingPageData.landingPageData.price_type);

const handlePriceAsk = (sku) => {
  handleCheckboxChange(sku);
  handleCloseModal();
  handleAskForProducts();
}


  return (
    <Modal show={showModal} onHide={handleCloseModal} size="lg" className="product-modal">
      <Modal.Header closeButton>
        <Modal.Title>{productName(product)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex p-5">
          <div className="row w-100">
            <div className="col-12 col-md-4 d-flex flex-column justify-content-start">
              <div>
                <ImageWithCheck 
                  src={`${proxy}/img/main/600/${product.sku}`} 
                  alt={product.sku} 
                  className="w-100 shadow mb-5"
                />
                <ImageWithCheck 
                  src={`${proxy}/img/main/600/${product.sku}/PAC01`} 
                  alt={product.sku} 
                  className="w-100 shadow mb-5"
                />
              </div>
            </div>
            <div className="col-12 col-md-8 d-flex justify-content-center">
              <ProductTable data={product} />
            </div>
            <div className="col-12 d-flex justify-content-end mt-5 mb-0">
              <span className="me-3 align-self-end text-end fs-3 justify-content-center align-content-center">
              {landingPageData.landingPageData.price_type ? landingPageData.landingPageData.price_type : t('price')}: <strong>{landingPageData.landingPageData.currency} {productPrice}</strong> 
              {
              productPrice === "" ? 
                <button className="btn btn-light shadow"
                onClick = {() => handlePriceAsk(product.sku)}
              >{t('ask_for_price')}</button> : " " 
              }
            </span>
            </div>
          
          </div>
        </div>

        <Container fluid>
          <ShowProductImagesWithResize selectedSKU={product.sku} showResize={false} showDownloadButton={true} targetTrans='landing_page'/>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
