import React, { useState } from 'react';
import axiosInstance from '../../setup/axiosInstance';
import { Card, Button, Form, Container, Spinner, Alert } from 'react-bootstrap';
import proxy from '../../setup/proxy';

const CsvUploader = ({ endpoint, showHeadersRow }) => {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [headersRow, setHeadersRow] = useState('');
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage('Please upload a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('csvFile', file);

    if (showHeadersRow && headersRow) {
      formData.append('headersRow', headersRow);
    }

    try {
      setLoading(true);
      setMessage('');

      const res = await axiosInstance.post(`${proxy}${endpoint}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResponse(res.data);
      setMessage('File uploaded successfully.');
    } catch (error) {
      console.error("Error uploading the file:", error);
      setResponse({ error: error.message });
      setMessage('Error uploading the file.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" >
      <Card className="m-3" style={{ maxWidth: '800px', width: '100%' }}>
        <Card.Body>
          <Card.Title>Upload CSV File</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select CSV file</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} accept=".csv" />
            </Form.Group>

            {/* Pole Headers Row - renderowane tylko jeśli showHeadersRow === true */}
            {showHeadersRow && (
              <Form.Group controlId="headersRow" className="mb-3">
                <Form.Label>Headers Row</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter headers row (optional)"
                  value={headersRow}
                  onChange={(e) => setHeadersRow(e.target.value)}
                />
              </Form.Group>
            )}

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> Processing...
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </Form>

          {/* Wyświetlanie wiadomości o sukcesie lub błędzie */}
          {message && (
            <Alert variant={response?.error ? 'danger' : 'success'} className="mt-3">
              {message}
            </Alert>
          )}

          {/* Wyświetlanie odpowiedzi z backendu */}
          {response && !response.error && (
            <div className="mt-3">
              <Card.Text>
                <strong>Response:</strong>
                <ul>
                  {response.addOrUpdate && (
                    <li>
                      <strong>Add/Update:</strong> {response.addOrUpdate.message}
                    </li>
                  )}
                  {response.delete && (
                    <li>
                      <strong>Delete:</strong> {response.delete.message}
                    </li>
                  )}
                </ul>
              </Card.Text>
            </div>
          )}

          {/* Wyświetlanie błędu, jeśli istnieje */}
          {response?.error && (
            <div className="mt-3">
              <Card.Text>
                <strong>Error:</strong> {response.error}
              </Card.Text>
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CsvUploader;
