import React from "react";
import DescriptionsSKUform from "../../components/descriptions/DescriptionsSKUform";
import DescriptionsUploadCSVForm from "../../components/descriptions/DescriptionsUploadCSVForm";
import CsvUploader from "../../components/forms/CsvUploader";
import BatchesTable from "../../components/descriptions/BatchesTable";
import GptBatchMonitor from "../../components/GptBatchMonitor";

const DescriptionsBulkGeneration = () => {
    return (
        <div>
            <DescriptionsSKUform />
            <CsvUploader endpoint="/api/desc-gen/generate-batch" />
            <GptBatchMonitor />
        </div>
    );
};

export default DescriptionsBulkGeneration;