import React, { useState } from "react";
import ReactDOM from "react-dom";

const ConfirmModal = ({ isOpen, question, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.5)" }}>
      <div style={{ margin: "10% auto", padding: "20px", background: "#fff", width: "300px", textAlign: "center" }}>
        <p>{question}</p>
        <button onClick={onConfirm}>Yes</button>
        <button onClick={onCancel}>No</button>
      </div>
    </div>,
    document.body
  );
};

export const useConfirm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState("");

  const confirm = (question) => {
    setCurrentQuestion(question);
    setIsOpen(true);

    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    }).then((result) => {
      setIsOpen(false);
      return result;
    });
  };

  const handleConfirm = () => {
    resolvePromise(true);
  };

  const handleCancel = () => {
    resolvePromise(false);
  };

  const modal = (
    <ConfirmModal
      isOpen={isOpen}
      question={currentQuestion}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );

  return { confirm, modal };
};
