// src/services/landingPageService.js

import axiosInstance from '../setup/axiosInstance';
import axiosPublicInstance from '../setup/axiosPublicInstance';
import proxy from '../setup/proxy';

export const fetchLandingPageData = async (pageKey) => {
  try {
    const response = await axiosPublicInstance.get(`${proxy}/api/landing-page/get-cards-data?page_key=${pageKey}`);

    const cleanedData = cleanData(response.data);

    const language = cleanedData.landingPageData?.lang || 'en'; // Pobierz język oferty
   
    const skus = getSkuList(cleanedData); // Pobierz listę SKU

    return {
      data: cleanedData,
      language,
      skus,
    };
  } catch (error) {
    if (error.response?.status === 404) {
      return { data: '404', language: 'en', skus: [] }; // Obsługa 404
    }
    console.error("Error fetching landing page data:", error);
    throw error;
  }
};

// czyszczenie danych  z pustych rekordów
const cleanData = (data) => {
  const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

  if (Array.isArray(data)) {
    return data.filter(item => !isEmptyObject(item) && item.sku !== "");
  } else if (typeof data === 'object' && data !== null) {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key] = data[key].filter(item => !isEmptyObject(item) && item.sku !== "");
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        data[key] = cleanData(data[key]);
      }
    }
    return data;
  }
  return data;
};

// get list of skus from cleaned data
const getSkuList = (cleanedData) => {
  const skus = JSON.parse(cleanedData.landingPageData?.skus || '[]')
    .filter((sku) => sku.sku)
    .map((sku) => ({ sku: sku.sku })); // Przetwórz listę SKU

  return skus;
}

// check if offer is expired
export const isOfferExpired = (creation_date, exp_date, expirationSpan) => {
  if (!creation_date) return true;

  const dateToCompare = new Date("2023-01-01T00:00:00.000Z").getTime(); // Pierwszy dzień 2023 roku

  let expDate = new Date(exp_date).getTime();
  const creationDate = new Date(creation_date).getTime();
  const now = new Date().getTime()

  if (expDate < dateToCompare)  { 
    expDate = creationDate + expirationSpan * 24 * 60 * 60 * 1000;
  }

  return expDate < now;
}