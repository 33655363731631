// components/descriptions/FormField.js

const Field = ({ label, name, value, onChange, type = "text", textarea = false }) => (
    <div className="form-group row">
      <label className="col-12 col-sm-2 col-form-label">{label}:</label>
      <div className="col-12 col-sm-10">
        {textarea ? (
          <textarea
            className="form-control "
            name={name}
            value={value || ""}
            onChange={onChange}
            style={{ height: "200px" }}
          />
        ) : (
          <input
            type={type}
            className="form-control"
            name={name}
            value={value || ""}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
  
export default Field;