// src/layouts/AdminLayout.js

import React from "react";
import AdminNavigation from "../components/navigation/AdminNavigation";
import useTranslationStore from "../stores/TranslationStore";
import { useTranslation } from "react-i18next";
import useLoadTranslations from '../hooks/useLoadTranslations.js';
import Footer from "../components/Footer";
import './AdminLayout.css';

const AdminLayout = ({ children }) => {
  const { userLang } = useTranslationStore();
  const namespace = 'common';
  useLoadTranslations(userLang, namespace); // Hook automatycznie zarządza stanem


  return (<>
      <div className="admin-container">
        <AdminNavigation />
        <div className="">{children}</div>  
      </div>
      <Footer />
    </>
  );
};

export default AdminLayout;
 