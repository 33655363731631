import React, { useContext } from "react";
import { Link, NavLink } from 'react-router-dom';
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import "./Navigation.css";
import { useTranslation } from 'react-i18next';
import useLoadTranslations from '../../hooks/useLoadTranslations.js';
import { usePopup } from '../../context/PopupContext.js';
import proxy from '../../setup/proxy.js';
import axios from 'axios';

// import bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//import { DownloadImages } from "../services/imageService";

// Components
import LanguageSelector from "../utilities/LanguageSelector.js";

// iomport landing page context
import { useLandingPageContext } from "../../context/LandingPageContext.js";

// Import AuthContext 
import { AuthContext } from '../../context/AuthContext.js';

// hooks
import useLandingPageData from "../../hooks/useLandingPageData.js";

//stores
import useTranslationStore from "../../stores/TranslationStore.js";




function Navigation() {
  // ustawienie tłumaczeń
  const { userLang, setUserLang } = useTranslationStore((state) => state);
  const isReady = useTranslationStore((state) => state.isReady);
  const { t } = useTranslation('common');

  const handleLanguageChange = (lang) => {
    setUserLang(lang);
  };

  const { roles, logout } = useContext(AuthContext);

  
  const hasAccess = (role) => roles.includes(role);


// LOADING HANDLERS
if (!isReady) return <p>Loading translations...</p>;


  // Konfiguracja menu
  const menus = [
    {
      id: "visuals",
      label: t("visuals"),
      options: [
        {
          key: "image_order",
          access: ["superuser", "image_order"],
          label: t("image_order"),
          to: "/image-order",
        },
        {
          key: "visual_browser",
          access: ["superuser", "visual_browser"],
          label: t("visuals_browser"),
          to: "/image-show",
        },
      ],
    },
    {
      id: "products",
      label: t("products"),
      options: [
        {
          key: "get_products_data",
          access: ["superuser", "internal_quotation", "customer_quotation"],
          label: t("get_products_data"),
          to: "/get-products-data",
        },
        {
          key: "update_products_data",
          access: ["superuser"],
          label: t("update_products_data"),
          to: "/update-products-data",
        },
        {
          key: "products_browser",
          access: ["internal_quotation", "superuser"],
          label: t("products_browser"),
          to: "/products-browser",
        },
        {
          key: "sku_ean_converter",
          access: ["superuser"],
          label: t("sku_ean_converter"),
          to: "/sku-ean-converter",
        },
      ],
    },
    {
      id: "descriptions",
      label: t("descriptions"),
      options: [
        {
          key: "descriptions_editor",
          access: ["superuser", "description_viewer"],
          label: hasAccess("description_viewer")
            ? t("descriptions_editor")
            : t("descriptions_viewer"),
          to: "/descriptions-editor",
        },
        {
          key: "export_excel",
          access: ["superuser", "description_viewer"],
          label: t("export_excel"),
          to: "/descriptions-export-excel",
        },
        {
          key: "bulk_generation",
          access: ["superuser"],
          label: t("bulk_generation"),
          to: "/descriptions-bulk-generation",
        },
      ],
    },
  ];

  // Funkcja pomocnicza do renderowania menu
  const renderMenu = (menu) => {
    const accessibleOptions = menu.options.filter((option) =>
      option.access.some(hasAccess)
    );

    if (accessibleOptions.length === 0) {
      return null;
    }

    return (
      <Dropdown as={Nav.Item} key={menu.id}>
        <Dropdown.Toggle
          as={Nav.Link}
          className="active-link"
          id={`${menu.id}-dropdown`}
        >
          {menu.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {accessibleOptions.map((option) => (
            <Dropdown.Item
              key={option.key}
              as={NavLink}
              to={option.to}
              className="active-link"
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // if (!isReady) return <p>Loading translations...</p>;

  return (
    <Navbar bg="light" expand="lg" className="sticky-top nav-bar-top shadow">
      <Container fluid>
        <Navbar.Brand>
          <img
            src={`${proxy}/content/logos/compactor-logo.png`}
            className="nav-bar-top-logo"
            alt="Compactor logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto nav-separator">
            <Nav.Item>
              <Nav.Link as={NavLink} to="/" className="active-link">
                {t("home")}
              </Nav.Link>
            </Nav.Item>

            {/* Renderowanie dynamicznych menu */}
            {menus.map(renderMenu)}

            {/* Statyczne elementy */}
            {(hasAccess("superuser") || hasAccess("landing_page_editor")) && (
              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  to="/landing-page-editor"
                  className="active-link"
                >
                  {t("landing_pages_editor")}
                </Nav.Link>
              </Nav.Item>
            )}
            {(hasAccess("superuser") || hasAccess("users_editor")) && (
              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  to="/users-editor"
                  className="active-link"
                >
                  {t("users_editor")}
                </Nav.Link>
              </Nav.Item>
            )}
            {(hasAccess("superuser") || hasAccess("admin")) && (
              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  to="/admin-control"
                  className="active-link"
                >
                  {t("admin_control")}
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <LanguageSelector selectedLanguage={userLang} onChangeLanguage={handleLanguageChange} />
          <div className="ps-5 px-0 m-0 align-content-center">
           
            <button className="btn btn-secondary btn-sm" onClick={logout}>
              {t("logout")}
            </button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

}

export default Navigation;
