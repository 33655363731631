import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./setup/i18n"; // Upewnij się, że importujesz konfigurację
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./setup/queryClient";
import { AuthProvider, AuthContext } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
  <QueryClientProvider client={queryClient}>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </QueryClientProvider>
  </AuthProvider>
);
reportWebVitals();
