import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // Cache pozostaje ważny przez 5 minut
      cacheTime: 10 * 60 * 1000, // Dane są przechowywane przez 10 minut
      retry: 1, // Ponów jedno nieudane żądanie
      refetchOnWindowFocus: false, // Nie odświeżaj danych po odzyskaniu focusa okna
    },
  },
});

export default queryClient;
