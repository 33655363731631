import React from "react";
import "./Tables.css";
import { useTranslation } from "react-i18next";
import useTranslationStore from "../../stores/TranslationStore";

// Funkcja do formatowania wartości na podstawie klucza
const formatValue = (key, value) => {
//  if (value === null || value === 0 || value < 0.00000001) return null;

  switch (key) {
    case "business_description_en":
    case "business_description_fr":
    case "business_description_de":
    case "business_description_es":
    case "business_description_it":
    case "business_description_pl":
      return value?.split("\n").map((line, idx) => (
        <span key={idx}>
          {line}
          <br />
        </span>
      ));
    case "product_gross_weight_with_packaging":
    case "product_net_weight_without_packaging":
      return `${value} kg`;
    case "quantity_in_pack":
      if (value < 2 || value === "" || value === null) {
        return "Single";
      }
      return parseInt(value);
    case "pcb":
      return `${parseInt(value)} pcs`;
    default:
      return value;
  }
};

// Funkcja do renderowania pojedynczego wiersza
const renderRow = (t, key, value) => {
  if (value === null || value === 0 || value < 0.00000001) return null;

  const displayKey = key.startsWith("business_description_")
    ? "business_description"
    : key;
  const displayValue = formatValue(key, value);

  return (
    <tr key={key}>
      <th scope="row">{t(displayKey)}</th>
      <td>{displayValue}</td>
    </tr>
  );
};

const ProductTable = ({ data }) => {
  const { t } = useTranslation("landing_page");
  const isReady = useTranslationStore((state) => state.isReady);
  const { clientLang } = useTranslationStore((state) => state);

  if (!isReady) return <p>Loading translations...</p>;

  const businessDescriptionKey = data[`business_description_${clientLang}`]
    ? `business_description_${clientLang}`
    : "business_description";

  const keysToShow = [
    "sku",
    businessDescriptionKey,
    "commercial_category",
    "material",
    "ean",
    "pcb",
    "packaging",
    "quantity_in_pack",
    "packaging_dimensions",
    "product_gross_weight_with_packaging",
    "product_net_weight_without_packaging",
    "carton_dimensions",
    "cbm",
    "pcs20_28cbm2",
    "pcs40_56cbm2",
    "pcs40hq_66cbm2",
  ];

  // filter empty data
  const filteredKeysToShow = keysToShow.filter(
    (key) => data[key] !== null && data[key] !== undefined && data[key] !== "" && data[key] !== " x  x  cm" && data[key] !== 0 
  );

  return (
    <table className="table table-responsive-sm table-light table-product">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">{t("product_data")}</th>
        </tr>
      </thead>
      <tbody>
      {filteredKeysToShow.map((key) => renderRow(t, key, data[key]))}
      </tbody>
    </table>
  );
};

export { ProductTable };
