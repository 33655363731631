import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPageLayout from "../layouts/LandingPageLayout";
import LandingPage from "../pages/public/LandingPage";
import Contact from "../pages/public/ContactPage";

const LandingPageRoutes = () => {
  return (
    <LandingPageLayout>
      <Routes>
           <Route path="/:page_key_params/contact" element={<LandingPage manualPageKey = {null}/>} />
           <Route path="/:page_key_params/:sku" element={<LandingPage />} />
           <Route path="/:page_key_params" element={<LandingPage />} />
           <Route path="/*" element={<LandingPage />} />
      </Routes>
    </LandingPageLayout>
  );
};

export default LandingPageRoutes;
