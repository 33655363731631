// src/components/landingPages/DisplayCards.js

import { productName, productSize } from "../../utils/productDataUtils.js";
import { CategoryDivider } from "../visual/headers.js";
import ProductCard from "../ProductCard.js";
import proxy from "../../setup/proxy.js";



const DisplayCards = ({ page_key, t, handleProductClick, handleCheckboxChange, selectedSkus, filteredProducts, normalizeString, clientLang}) => {

  // Grupowanie przefiltrowanych produktów
  const groupedFilteredProducts = filteredProducts.reduce((acc, product) => {
    let normalizedCategory = normalizeString(product.business_category) || "other";
    console.log("normalizedCategory", normalizedCategory);
    const translatedCategory = t(normalizedCategory);
    if (!acc[translatedCategory]) {
      acc[translatedCategory] = [];
    }
    acc[translatedCategory].push(product);
    return acc;
  }, {});

  console.log("groupedFilteredProducts", groupedFilteredProducts);

return (
  <>
   
    <div className="landing-page-categories-container w-100">


      {Object.keys(groupedFilteredProducts).map((category) => (
        <div key={t(category)} className="category-section row mt-5 mx-0">
          <CategoryDivider category={t(category)} />
          <div className="d-flex justify-content-center flex-wrap">
            {groupedFilteredProducts[category].map((product) => (
             <>
                <ProductCard
                  key={product.sku}
                  imageSrc={`${proxy}/img/main/300/${product.sku}`} // Dostosuj ścieżkę do obrazów
                  productName={productName(product, clientLang)}
                  productCode={product.sku}
                  productEan={product.ean}
                  productSize={productSize(product)}
                  sizeTag={product.size_tag}
                  pageKey={page_key}
                  onClick={() => handleProductClick(product)} // Dodajemy handler kliknięcia
                  handleCheckboxChange={() =>
                    handleCheckboxChange(product.sku)
                  } // Dodajemy handler checkboxa
                  isChecked={selectedSkus.includes(product.sku)} // Sprawdzamy, czy SKU jest zaznaczone
                  price={product.product_price}
                />
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  </>
);
};
  

  export default DisplayCards;