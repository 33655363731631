

  // funckcje do wyświetlania nazwy produktu i rozmiaru
 export const productName = (product, lang) => {
  console.log("product", product);
  if (!product.business_description) {
    return "";
  }

    let name;
    switch (lang) {
      case "pl":
        name = product.business_description_pl || product.business_description;
        break;
      case "en":
        name = product.business_description || product.business_description;
        break;
      case "de":
        name = product.business_description_de || product.business_description;
        break;
      case "fr":
        name = product.business_description_fr || product.business_description;
        break;
      case "it":
        name = product.business_description_it || product.business_description;
        break;
      case "es":
        name = product.business_description_es || product.business_description;
        break;
      case "nl":
        name = product.business_description_nl || product.business_description;
        break;
      default:
        name = product.business_description;
    }



    return name.split("\n")[0];
  };

  /**
   * returns product dimensions in cm
   * @param {*} product 
   * @returns 
   */
  export const productSize = (product) => {

    if (product.product_width_cm == 0) product.product_width_cm = null;
    if (product.product_depth_cm == 0) product.product_depth_cm = null;
    if (product.product_height_cm == 0) product.product_height_cm = null;


    let size = "";
    if (
      product.product_width_cm === null &&
      product.product_depth_cm === null &&
      product.product_height_cm === null
    ) {
      return "---";
    }
    if (
      product.product_width_cm !== null &&
      product.product_width_cm !== 0
    ) {
      size += `${product.product_width_cm} x `;
    }
    if (
      product.product_depth_cm !== null &&
      product.product_depth_cm !== 0
    ) {
      size += `${product.product_depth_cm} x `;
    }
    if (
      product.product_height_cm !== null &&
      product.product_height_cm !== 0
    ) {
      size += `${product.product_height_cm}`;
    }
    size += " cm";
    return size;
  };
