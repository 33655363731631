import CsvUploader from "../../components/forms/CsvUploader";

const UpdateProductsData = () => {
    return (
        <div>
        <CsvUploader endpoint="/api/sync/costing" showHeadersRow={true} />
        </div>
    );
    }

export default UpdateProductsData;